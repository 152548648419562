<template>
  <div id="chart">
    <apexchart type="radialBar" height="250" :options="chartOptions" :series="series"></apexchart>
  </div>
</template>

<script>
import Vue from "vue";

import VueApexCharts from "vue-apexcharts";
Vue.use(VueApexCharts);
Vue.component("apexchart", VueApexCharts);

export default {
  name: "BrushCharts",
  data: function() {
    return {

      series: [69],
      chartOptions: {
        chart: {
          height: 280,
          type: 'radialBar',
        },
        plotOptions: {
          radialBar: {
            hollow: {
              margin: 15,
              size: '70%',
            },

            startAngle: -135,
            endAngle: 135,
            track: {
              background: 'rgba(150,150,150,0.22)',
              startAngle: -135,
              endAngle: 135,
            },
            dataLabels: {
              name: {
                show: true,
              },
              value: {
                fontSize: '30px',
                show: true,
                formatter: (val) => `${val}%`,
              },
            },
          },
        },
        fill: {
          type: 'color',
          colors: ['#3a88d7'],
        },
        stroke: {
          lineCap: 'round',
        },
        labels: [' '],
      }}},

};
</script>

<style scoped>
#chart1,
#chart2 {
  max-width: 650px;
  margin: 35px auto;
}

#chart2 {
  position: relative;
  margin-top: -70px;
  margin-bottom: 0px;
}

#app {
  padding-top: 20px;
  padding-left: 10px;
  background: #fff;
  border: 1px solid #ddd;
  box-shadow: 0 22px 35px -16px rgba(0, 0, 0, 0.1);
  max-width: 650px;
  margin: 35px auto;
}
</style>
