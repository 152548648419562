<template>
  <v-row class="footer" style="margin: 0px">
    <v-col lg="3" style="padding-right: 40px;height:100%">
      <h5>راهنما</h5>
      <p>
        <span>::</span>
        راهنمای ثبت نام</p>
      <p>
        <span>::</span>
        سوالات متداول</p>
      <p>
        <span>::</span>
        راهنمای احراز هویت و سطح کاربری</p>
    </v-col>
    <v-col lg="3" style="height:100%">
      <h5>ساترکس</h5>
      <p><span>::</span> درباره ما</p>
      <p><span>::</span> ارتباط با ما</p>
      <p><span>::</span> قوانین و شرایط</p>
    </v-col>
    <v-col lg="3" style="height:100%">
      <h5>اطلاعات تماس</h5>
      <p><span>::</span> مشهد-بلوار صیادشیرازی-مجتمع تجاری ستاسنتر</p>
      <p style="height: 40.2px;display:flex;direction:rtl;gap:8px;align-items:center;"><i class="flaticon-support" style="height:25px;margin-bottom:0;"></i>09154785489</p>
      <p style="height: 40.2px;display:flex;direction:rtl;gap:8px;align-items:center;"><i class="flaticon-mail" style="height:25px;margin-bottom:0;"></i>support@satrex.ir</p>
    </v-col>
    <v-col lg="3" style="height:100%">
      <h5>ما در شبکه های اجتماعی</h5>
    </v-col>
  </v-row>
</template>

<script>
export default {
  name: "Footer.vue"
}
</script>

<style scoped>

</style>