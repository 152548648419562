<template>
  <div class="rtl">
    <v-row style="direction: rtl">
      <div class="online_content">
        <v-col>
          <v-row>
            <v-col cols="12">
              <v-card height="240px" style="margin-top: unset !important;padding: 15px;font-size: 14px;text-align: center ">
                <verifyChart />
              </v-card>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="6" style="padding-top: 4px">
              <v-card min-height="100px" style="margin-top: unset !important;padding: 10px;font-size: 14px;text-align: center;display: flex;flex-direction: column;justify-content:space-between">
                <div style="margin-top: 10px"><span class="panelName">مجموع کل کاربران</span></div>
                <div><span class="panelNum" style="color: rgb(2, 181, 160)">{{ allUsers }}</span></div>
              </v-card>
            </v-col>
            <v-col cols="6" style="padding-top: 4px" >
              <v-card min-height="100px" class="hoverGray" style="cursor: pointer; margin-top: unset !important;padding: 10px;font-size: 14px;text-align: center;display: flex;flex-direction: column;justify-content:space-between">
                <router-link to="confirmationStatusList">
                  <div style="margin-top: 10px ;font-size: 12px">
                    <span class="panelName" @click="$root.confirmationType = 'VerifiedBySystem'">کاربران تایید شده توسط سیستم</span>
                  </div>
                </router-link>
              </v-card>
            </v-col>
            <v-col cols="6" v-for="(item, index) in UserQualifyLevelList" :key="index" style="padding-top: 4px" >
              <v-card
                min-height="100px" class="hoverGray"
                style="cursor: pointer; margin-top: unset !important;padding: 10px;font-size: 14px;text-align: center;display: flex;flex-direction: column;justify-content:space-between"
              >
                <router-link tag="span" to="/filterUsersList" >
                  <div style="margin-top: 10px" >
                    <span class="panelName" @click="$root.choosedLevelId = item.id">کاربران سطح {{ item.title }}</span>
                  </div>
                  <div>
                    <span class="panelNum" @click="$root.choosedLevelId = item.id">{{ item.userCount }}</span>
                  </div>
                </router-link>
              </v-card>
            </v-col>
            <v-col cols="6" style="padding-top: 4px">
              <v-card min-height="100px" style="margin-top: unset !important;padding: 10px;font-size: 14px;text-align: center;display: flex;flex-direction: column;justify-content:space-between">
                <div style="margin-top: 10px"><span class="panelName">ارزش معاملات 30 روز گذشته</span></div>
              </v-card>
            </v-col>
            <v-col cols="6"  style="margin-top: unset !important;padding: 10px;font-size: 14px;text-align: center;display: flex;flex-direction: column;justify-content:space-between">
              <router-link to="confirmationStatusList">
                <div class="hoverGreen" style="cursor: pointer; padding-right: 10px; height: 100px; padding: 20px 0px 0px; text-align: center;border: dashed 1px#02b5a0;border-radius: 8px;">
                  <span class="panelName" @click="$root.confirmationType = 'ConfirmRequest'" style="color: rgb(2, 181, 160); white-space: nowrap;">در انتظار تایید</span>
                </div>
              </router-link>
            </v-col>
            <v-col cols="6"  style="margin-top: unset !important;padding: 10px;font-size: 14px;text-align: center;display: flex;flex-direction: column;justify-content:space-between">
              <router-link to="confirmationStatusList">
                <div class="hoverRed" style="cursor: pointer; height: 100px;padding: 20px 0px 0px; text-align: center;border: dashed 1px red;border-radius: 8px;">
                  <span class="panelName" @click="$root.confirmationType = 'Rejected'" style="color: red; white-space: nowrap;">رد شده</span>
                </div>
              </router-link>
            </v-col>
          </v-row>
        </v-col>
      </div>
      <div class="online_chart">
        <v-col style="padding-top: unset;height:100%">
          <v-row style="height:100%">
            <v-col cols="12" style="height:100%">
              <v-card class="mx-auto" style="margin: 10px;height:100%">
                <v-list style="margin: 10px">
                  <h4 class="panelTitle">کاربران آنلاین</h4>
                  <v-row style="margin-top: 10px;margin-left: 10px">
                    <div style="padding-right: 30px;width: 40%">
                      <div>
                        <span>0</span>
                      </div>
                      <div>
                        <span style="font-size: 10px">تعداد کاربران امروز</span>
                      </div>
                    </div>
                    <div style="width: 60%">
                      <router-link to="/filterOnlineUsersList">
                        <label style="cursor: pointer; color: #02b5a0; height: 20px; border: 1px solid rgb(58, 136, 215); border-radius: 4px; padding: 5px 20px; font-size: 15px;" @click="$root.filterOnlineUsers = 'user'">
                          {{
                              onlineUsers.onlineUsersCount
                          }} کاربران آنلاین
                        </label>
                      </router-link>
                    </div>
                  </v-row>
                  <v-row>
                    <v-col cols="12">
                      <OnlineChart />
                    </v-col>
                  </v-row>
                  <div style="margin-right: 36px;padding-bottom: 10px;margin-top: 10px">
                    <v-row>
                      <v-col>
                        <router-link to="/filterOnlineUsersList">
                          <span @click="$root.filterOnlineUsers = 'admin'">{{ onlineUsers.onlineAdminsCount }}</span>
                        </router-link>
                      </v-col>
                      <v-col>
                        <router-link to="/filterOnlineUsersList">
                          <span @click="$root.filterOnlineUsers = 'admin'">تعداد ادمین های آنلاین</span>
                        </router-link>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col>{{ OnlineUsersCountByDevice.desktopUserCount }}</v-col>
                      <v-col>
                        <span>کاربران ویندوزی</span>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col>{{ OnlineUsersCountByDevice.mobileUserCount }}</v-col>
                      <v-col>
                        <span>کاربران موبایلی</span>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col>{{ OnlineUsersCountByDevice.otherDeviceUserCount }}</v-col>
                      <v-col>
                        <span>سایر دستگاه ها</span>
                      </v-col>
                    </v-row>
                  </div>
                </v-list>
              </v-card>
            </v-col>
          </v-row>
        </v-col>
      </div>
    </v-row>
    <!-- <v-row style="padding: 4px;">
      <v-card width="100%">
        <v-card-title style="margin-top: unset">
          <p class="panelTitle" style="margin: unset !important;margin-top: 16px!important;">مدیریت کاربران</p>
          <v-spacer></v-spacer>
          <v-text-field class="searchStyle" style="max-width: 200px;" v-model="search" append-icon="mdi-magnify" label="جست و جو" single-line hide-details></v-text-field>
        </v-card-title>
        <v-data-table :headers="headers" :items="userListForAdmin" :search="search">
          <template v-slot:item.operations="{ item }">
            <div style="display:flex;justify-content:space-around">
              <router-link :to="'/documentUserManage/' + item.username">
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn class="whiteButton btn_index iconBtn" v-bind="attrs" v-on="on">
                      <v-icon large>
                        mdi-folder-open
                      </v-icon>
                    </v-btn>

                  </template>
                  <span style="color:white">پرونده</span>
                </v-tooltip>
              </router-link>
              &nbsp;
              <router-link :to="'/turnOverUser/' + item.username" class="index_resize_btn">
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn class="whiteButton btn_index iconBtn" v-bind="attrs" @click="$root.choosedUser = item.username" v-on="on">
                      <v-icon large>
                        mdi-clipboard-text-clock-outline
                      </v-icon>
                    </v-btn>
                  </template>
                  <span style="color:white">سوابق مالی</span>
                </v-tooltip>

              </router-link>
              &nbsp;
              <router-link to="/securityUser">
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn class="whiteButton btn_index iconBtn" v-bind="attrs" @click="$root.choosedUser = item.username" v-on="on">
                      <v-icon large>
                        mdi-shield-account-outline
                      </v-icon>
                    </v-btn>

                  </template>
                  <span style="color:white">امنیت</span>
                </v-tooltip>
              </router-link>
              &nbsp;
              <router-link to="/userMessages" class="index_resize_btn">
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn class="whiteButton btn_index iconBtn" v-bind="attrs" @click="$root.choosedUser = item.username" v-on="on">
                      <v-icon large>
                        mdi-message-settings-outline
                      </v-icon>
                    </v-btn>
                  </template>
                  <span style="color:white">پیام ها</span>
                </v-tooltip>
              </router-link>
            </div>
          </template>
        </v-data-table>
      </v-card>
    </v-row> -->
  </div>
</template>

<script>
import axiosApi from "@/axios";
import verifyChart from "@/components/verifyChart";
import OnlineChart from "@/components/OnlineChart";

export default {
  components: {
    verifyChart, OnlineChart
  },
  name: 'Index',
  data() {
    return {
      userListForAdmin: [],
      UserQualifyLevelList: [],
      OnlineUsersCountByDevice: [],
      allUsers: 0,
      onlineUsers: '',
      onlineAdminsCount: 1,
      onlineUsersCount: 1,
      headers: [
        { text: 'شناسه', value: 'id' },
        {
          text: 'نام ',
          align: 'start',
          sortable: false,
          value: 'firstName',
        },
        { text: 'نام خوانوادگی', value: 'lastName' },
        { text: 'کد ملی', value: 'nationalId' },
        { text: 'شناسه کاربری', value: 'username' },
        { text: 'عملیات', value: 'operations' },
      ],
      search: ''

    };
  },
  mounted() {
    this.getUsersAndStatus();
    this.GetQualifyLevelList();
    this.getUsersOnline();
    this.GetOnlineUsersCountByDevice();
    document.getElementById('SvgjsText1041').classList.add('remove');
  },
  methods: {
    getUsersAndStatus() {
      axiosApi().post('/api/Manage/v1/User/GetList', {}).then(({ data }) => {
        if (data['isSuccess'] === true)
          this.userListForAdmin = data.data;
      },);
    },
    GetQualifyLevelList() {
      axiosApi().post('api/Manage/v1/Level/GetQualifyLevelList')
        .then(({ data }) => {
          this.UserQualifyLevelList = data.data;
          this.SumUser();
        });
    },
    SumUser() {
      this.UserQualifyLevelList.forEach((item) => {
        this.allUsers += item.userCount;
      });
    },
    getUsersOnline() {
      axiosApi().post('/api/Manage/v1/Report/GetOnlineUsersCount')
        .then(({ data }) => {
          this.onlineUsers = data.data;
        });
    },
    GetOnlineUsersCountByDevice() {
      axiosApi().post('/api/Manage/v1/Report/GetOnlineUsersCountByDevice')
        .then(({ data }) => {
          this.OnlineUsersCountByDevice = data.data;
        });
    }
  }
};
</script>
<style rel="scss">
.card {
  background-color: red;
  min-width: 32.33%;
  min-height: 250px;
  padding: 20px;
}
.remove {
  visibility: hidden;
}
.btn_index {
  margin-left: 5px;
  /*min-width: 129px !important;*/
}
.col-xl,
.col-xl-auto,
.col-xl-12,
.col-xl-11,
.col-xl-10,
.col-xl-9,
.col-xl-8,
.col-xl-7,
.col-xl-6,
.col-xl-5,
.col-xl-4,
.col-xl-3,
.col-xl-2,
.col-xl-1,
.col-lg,
.col-lg-auto,
.col-lg-12,
.col-lg-11,
.col-lg-10,
.col-lg-9,
.col-lg-8,
.col-lg-7,
.col-lg-6,
.col-lg-5,
.col-lg-4,
.col-lg-3,
.col-lg-2,
.col-lg-1,
.col-md,
.col-md-auto,
.col-md-12,
.col-md-11,
.col-md-10,
.col-md-9,
.col-md-8,
.col-md-7,
.col-md-6,
.col-md-5,
.col-md-4,
.col-md-3,
.col-md-2,
.col-md-1,
.col-sm,
.col-sm-auto,
.col-sm-12,
.col-sm-11,
.col-sm-10,
.col-sm-9,
.col-sm-8,
.col-sm-7,
.col-sm-6,
.col-sm-5,
.col-sm-4,
.col-sm-3,
.col-sm-2,
.col-sm-1,
.col,
.col-auto,
.col-12,
.col-11,
.col-10,
.col-9,
.col-8,
.col-7,
.col-6,
.col-5,
.col-4,
.col-3,
.col-2,
.col-1 {
  padding: 9px;
}
.online_chart {
  width: 65%;
}
.online_content {
  width: 35%;
}
@media only screen and (max-width: 800px) {
  .online_chart {
    width: 100%;
  }
  .online_content {
    width: 100%;
  }
  .index_resize_btn {
    display: none;
  }
}
@media only screen and (max-width: 500px) {
  .index_resize_btn {
    display: none;
  }
  .btn_index {
    min-width: 50px !important;
  }
}
</style>