import Vue from 'vue'
import App from './App.vue'
import vuetify from './plugins/vuetify'
import router from './router'
import VueToast from 'vue-toast-notification';
import 'vue-toast-notification/dist/theme-sugar.css';
import VuePersianDatetimePicker from 'vue-persian-datetime-picker';
import store from "@/Store";
import VueApexCharts from 'vue-apexcharts'
Vue.use(VueApexCharts)
Vue.component('apexchart', VueApexCharts)


Number.prototype.toFixedSpecial = function(n) {
  var str = this.toFixed(n);
  if (str.indexOf('e+') === -1)
    return str;

  // if number is in scientific notation, pick (b)ase and (p)ower
  str = str.replace('.', '').split('e+').reduce(function(b, p) {
    return b + Array(p - b.length + 2).join(0);
  });

  if (n > 0)
    str += '.' + Array(n + 1).join(0);

  return str;
};

Vue.use(VuePersianDatetimePicker, {
  name: 'satrex-date-picker',
  props: {
    inputFormat: 'YYYY-MM-DD',
    format: 'jYYYY-jMM-jDD',
    editable: false,
    inputClass: 'form-control datePickerColorDark',
    placeholder: 'لطفا تاریخ  را انتخاب کنید',
    altFormat: 'YYYY-MM-DD',
    color: 'rgb(42,196,144) !important',
    autoSubmit: false,
    //...
    //... And whatever you want to set as default
    //...
  }
});

Vue.use(VueToast)
Vue.config.productionTip = false

new Vue({
  vuetify ,
  router,
  store : store ,
  linkActiveClass: "active",
  render: h => h(App)
}).$mount('#app')

export default {
  data(){
    return{
      confirmationType : '',
      choosedUser : '',
      choosedLevelId : '',
      filterOnlineUsers : '',
      isOpen: true,
      isHide: true,
    }
  },
  methods:{

  }
}
