import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home/index'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home

  },
  {
    path: '/customerClub',
    name : 'CustomerClub',
    component: () => import('../views/CustomerClub/index')
  },
  {
    path: '/documentUserManage/:username',
    name: 'documentUserManage',
    component: () => import('../views/DocumentUserManage.vue')
  },
  {
    path: '/marketAccounts',
    name : 'marketAccounts',
    component: () => import('../views/FinanceSetting/MarketAccounts')
  },
  {
    path: '/FastExchangePrice',
    name : 'FastExchangePrice',
    component: () => import('../views/FinanceSetting/FastExchangePrice')
  },
  {
    path: '/financialLevel',
    name : 'financialLevel',
    component: () => import('../views/FinanceSetting/FinancialLevel')
  },
  {
    path: '/accountingManage',
    name : 'accountingManage',
    component: () => import('../views/AccountingManage/AccountingManage')
  },
  {
    path: '/accountingManage/ExchangeInventory',
    name : 'ExchangeInventory',
    component: () => import('../views/AccountingManage/ExchangeInventory')
  },
  {
    path: '/SiteSetting',
    name : 'SiteSetting',
    component: () => import('../views/SiteSetting/SiteSetting')
  },
  {
    path: '/securityManage',
    name : 'SecurityManage',
    component: () => import('../views/SecurityManage/SecurityManage')
  },
  {
    path: '/userManage',
    name : 'UserManage',
    component: () => import('../views/UserManage/UserManage')
  },
  {
    path: '/AdminMessages',
    name : 'AdminMessages',
    component: () => import('../views/Message/AdminMessages')
  },
  {
    path: '/confirmationStatusList',
    name : 'confirmationStatusList',
    component: () => import('../views/ConfirmationStatusList')
  },
  {
    path: '/filterUsersList',
    name : 'FilterUsersList',
    component: () => import('../views/FilterUsersList')
  },
  {
    path: '/filterOnlineUsersList',
    name : 'filterOnlineUsersList',
    component: () => import('../views/FilterOnlineUsersList')
  },
  {
    path: '/userMessages',
    name : 'userMessages',
    component: () => import('../views/Message/UserMessages')
  },
  {
    path: '/securityUser',
    name : 'securityUser',
    component: () => import('../views/SecurityManage/SecurityUser')
  },
  {
    path: '/replyTicket',
    name : 'replyTicket',
    component: () => import('../views/Message/ReplyTicket')
  },
  {
    path: '/SpotOrdersQuickView',
    name : 'SpotOrdersQuickView',
    component: () => import('../views/SpotOrdersQuickView/SpotOrdersQuickView')
  },
  {
    path: '/userSettings',
    name : 'userSettings',
    component: () => import('../views/UserSettings/UserSettings')
  },
  {
    path: '/cryptoSettings',
    name : 'CryptoSettings',
    component: () => import('../views/CryptoSettings/CryptoSettings')
  },
  {
    path: '/marketSettings',
    name : 'marketSettings',
    component: () => import('../views/MarketSettings/MarketSettings')
  },
  {
    path: '/FinancialSettings',
    name : 'FinancialSetstings',
    component: () => import('../views/FinancialSettings/FinancialSettings')
  },
  {
    path: '/campaigns',
    name : 'campaigns',
    component: () => import('../views/Campaigns/index')
  },
  {
    path: '/campaigns/FinancialLevel',
    name : 'campaignsFinancialLevel',
    component: () => import('../views/Campaigns/financialLevel')
  },
  {
    path: '/TurnOverUser/:username',
    name : 'TurnOverUser',
    component: () => import('../views/TurnOver/GetTransactionsUser')
  },
  {
    path: '/deposit',
    name : 'Deposit',
    component: () => import('../views/deposits/Deposits')
  },
  {
    path: '/404',
    name : '404',
    component: () => import('../components/NotFound')
  },
  {
    path: '*',
    name: 'noPage',
    redirect: '/404'
  }
]
const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
  ,
  scrollBehavior()
  {
    setTimeout(()=>{
      document.getElementById('content').scrollTo({
        top: 0,
        left: 0,
        behavior: 'smooth'
      });
    },100)
  }
})

export default router
