import Vue from 'vue';
import Vuex from "vuex";

Vue.use( Vuex );

const store = new Vuex.Store( {
  state: {
    token: null,
    refreshToken: null
  },
  mutations: {
    setToken( state, token ) {
      state.token = token;
    },
    setRefreshToken( state, refreshToken ) {
      state.refreshToken = refreshToken;
    }
  }
} );
export default store;
