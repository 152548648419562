<template>
  <div style="height: 100%">
    <Header></Header>
    <div
      id="content"
      style="background-color: #f4f5f7;margin: unset;height: 100%;min-height: 570px"
      :class="[isOpen ? 'widthOpen' : 'widthClose']"
    >
      <v-container style="margin:0;max-width: unset !important;">
        <v-row>
          <v-col xs="12" cols="12" style="direction: rtl;padding-top: 60px">
            <v-col>
              <v-row>
                <img src="./assets/image/green.svg" style="width: 23px">
                <span class=" dot panelName">حجم معاملات روز ساترکس :{{ this.separate(Last24HoursTotal) }}</span>
              </v-row>
              <v-row>
              </v-row>
            </v-col>
          </v-col>
          <v-col xs="12" cols="12" style="padding: 40px;padding-top: 1rem" id="satrex">
            <v-app id="inspire">
              <router-view />
            </v-app>
          </v-col>
          <v-col xs="12" cols="12" style="padding:0" id="footer">
            <Footer></Footer>
          </v-col>
        </v-row>
      </v-container>
    </div>
    <Sidebar></Sidebar>
  </div>
</template>


<script>
import axiosApi from "@/axios";
import "./assets/styles/main.scss";
import "./assets/font/flaticon.css";
import Footer from "@/components/Footer";
import Sidebar from "@/components/Sidebar";
import Header from "@/components/Header";
import { mapState } from 'vuex';
import separator from "@/plugins/separate";

export default {
  components: { Header, Sidebar, Footer },
  data() {
    return {
      isOpen: true,
      isHide: true,
      Last24HoursTotal: ''
    };
  },
  methods: {
    separate( Number ) {
      return separator( Number );
    },
    GetLast24HoursTotal() {
      axiosApi().post( 'api/Manage/v1/Report/GetLast24HoursTotalCompletedOrdersPriceInToman' )
        .then( ( { data } ) => {
          this.Last24HoursTotal = data.data.totalPriceInToman;
        } );
    },
    toggleSidebar() {
      document.getElementById( "sidebar" ).classList.toggle( 'active' );
      this.isOpen = !this.isOpen;
      this.isHide = !this.isHide;
    },
    changeToken() {
      this.$store.commit( 'setToken', localStorage.getItem( 'token' ) );
      this.$store.commit( 'setRefreshToken', localStorage.getItem( 'refreshToken' ) );
    }
  },
  mounted() {
    this.changeToken();
    this.GetLast24HoursTotal();
  },
  computed: mapState( {
    token: 'token',
    refreshToken: 'refreshToken',
  } )
};
</script>

<style>
.theme--light.v-application {
  background: unset !important;
}
.v-application .success {
  background-color: #02b5a0 !important
}
@media only screen and (max-width: 960px) {
  #header-menu {
    display: none;
  }
  .widthOpen {
    width: calc(100% - 59px) !important;
  }
}
@media only screen and (max-width: 500px) {
  #satrex {
    padding: 15px !important;
  }
  #footer {
    display: none;
  }
}
</style>
