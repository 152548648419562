<template>
  <div>
    <v-row class="pageHeader">
      <div style="direction:rtl;margin:10px 20px 0 20px;cursor:default;" class="panelName">
        <span>امروز</span><span>:</span>
        <span>{{persianDate}}</span>&nbsp;&nbsp;&nbsp;
        <span>ساعت</span><span>:</span>
        <span>{{timestamp}}</span>
      </div>
      <!-- <div style="display: flex;align-items: center;" dir="rtl">
        <span style="font-family: IRANSans;opacity: 0.85;display: flex">
          <p class="p_time">امروز</p>
          <p>{{ persianDate }}</p>
          <p class="p_time">ساعت</p>
          <p>{{ timestamp }}</p>
        </span>
        &nbsp;&nbsp;&nbsp;
        <div id="img-support">
          <a href="https://raychat.io/admin" style="text-decoration: none;">
            <img src="../assets/image/raychat-logo-white.svg" style="width: 35px;margin-top: 5px"></a>
        </div>
      </div>-->

      <v-col id="header-menu" class="sidebar-menu">
        <v-row class="rtl headerLinkActive" style="font-size: 15px;height: 50px;padding: 0;">
          <v-col class="panelHeader">
            <router-link to="/">
              <p class="headerLinkDeActive panelName">داشبورد</p>
            </router-link>
          </v-col>
          <v-col class="panelHeader">
            <router-link to="/FinancialSettings">
              <p class="headerLinkDeActive panelName"> تنظیمات مالی</p>
            </router-link>
          </v-col>
          <v-col class="panelHeader">
            <router-link to="/SiteSetting">
              <p class="headerLinkDeActive panelName">تنظیمات سامانه</p>
            </router-link>
          </v-col>
          <v-col class="panelHeader">
            <router-link to="/userManage">
              <p class="headerLinkDeActive panelName">مدیریت کاربران</p>
            </router-link>
          </v-col>
          <v-col style="padding: 0"></v-col>
          <v-col style="padding: 0"></v-col>
        </v-row>
      </v-col>
      <v-col class="rtl">
        <v-row>
          <v-col cols="2" @click="$parent.toggleSidebar()">
            <v-col class="toggle-btn" style="margin-right: 15px;margin-top: unset">
              <span></span>
              <span></span>
              <span></span>
            </v-col>
          </v-col>
          <v-col cols="10">
            <div style="display: flex">
              <img src="../assets/image/satrexLogo.png" style="width: 35px;height: 35px;margin-right: 18px;">
              <img src="../assets/image/logo.svg" style="width: 98px;height: 35px;margin-top: 3px;margin-right: 18px;">
            </div>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import moment from "jalali-moment";
// import Vue from 'vue';
export default {
  name: "Header.vue",
  data() {
    return {
      timestamp: '',
      persianDate: '',
    };
  }
  ,
  mounted() {
    setInterval(this.getNow, 1000);
  },
  methods: {
    getNow() {
      const today = new Date();
      const time = today.getHours() + ":" + today.getMinutes();
      const dateTime = time;
      this.timestamp = dateTime;
      const persianD = moment();
      this.persianDate = persianD.locale('fa').format('jD jMMMM jYYYY');
    }
  }
};
</script>

<style scoped>
.greenHeaderSelect {
  color: #62ff00;
  background-color: unset !important;
}
p {
  background-color: unset !important;
}
.sidebar-menu {
  height: 100%;
  padding: 0;
  display: contents
}
@media only screen and (max-width: 495px) {
  .p_time {
    display: none;
  }
}
@media only screen and (max-width: 360px) {
  #img-support {
    display: none;
  }
}
</style>