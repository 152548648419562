<template>
  <div id="chart">
    <apexchart type="line" height="315" :options="chartOptions" :series="series"></apexchart>
  </div>
</template>

<script>
import Vue from "vue";

import VueApexCharts from "vue-apexcharts";
Vue.use(VueApexCharts);
Vue.component("apexchart", VueApexCharts);

export default {
  name: "BrushCharts",
  data: function () {

    const chartConfig = {
      chart: {
        height: 350,
        type: 'line',
        zoom: {
          enabled: false,
        },
        toolbar: {
          show: false,
        },
        fontFamily: 'enNum',
      },
      colors: ['#ffcb4e'],
      stroke: {
        curve: 'straight',
        width: 2,
      },
      fill: {
        type: 'gradient' / 'solid' / 'pattern' / 'image',
      },
      markers: {
        size: 5,
        colors: 'black',
        strokeColors: '#fff',
        strokeWidth: 2,
        strokeOpacity: 0.9,
        strokeDashArray: 0,
        fillOpacity: 1,
        discrete: [],
        shape: 'circle',
        radius: 2,
        offsetX: 0,
        offsetY: 0,
        onClick: undefined,
        onDblClick: undefined,
        showNullDataPoints: true,
        hover: {
          size: 7,
          colors: ['red'],
          sizeOffset: 3,
          fillColor: '#000',
          strokeColor: '#eee',
          fill: {
            colors: ['red'],
          },
        },
      },

      grid: {
        strokeDashArray: 2,
        borderColor: '#d2d2d2',
        row: {
          // takes an array which will be repeated on columns
          opacity: 0.5,
        },
      },
      yaxis: {

        labels: {
          show: true,
        },
      },
      xaxis: {
        categories: ['10', '11', '12', '13', '14', '15', '16'],
        tickAmount: undefined,
        tickPlacement: 'between',
        min: undefined,
        max: undefined,
        range: 12,
        floating: false,
        fill: {
          color: '#fff',
        },
        decimalsInFloat: undefined,
        position: 'bottom',
        labels: {
          show: true,
          rotate: -45,
          rotateAlways: false,
          hideOverlappingLabels: true,
          showDuplicates: false,
          trim: false,
          minHeight: 'unset',
          maxHeight: 120,
          style: {
            colors: [
              '#bebebe',
              '#bebebe',
              '#bebebe',
              '#bebebe',
              '#bebebe',
              '#bebebe',
              '#bebebe',
              '#bebebe',
              '#bebebe',
              '#bebebe',
              '#bebebe',
              '#bebebe',
            ],
            fontSize: '12px',
            fontWeight: 400,
          },
          offsetX: 0,
          offsetY: 0,
          format: undefined,
          formatter: undefined,
          datetimeUTC: true,
          type: 'numeric',
        },
      },
      tooltip: {
        enabled: true,
        enabledOnSeries: undefined,
        shared: true,
        followCursor: false,
        intersect: false,
        inverseOrder: false,
        custom: undefined,
        fillSeriesColor: false,
        theme: false,
        style: {
          fontSize: '20px',
          color: '#d2d2d2',
          backgroundColor: 'red',
        },
        onDatasetHover: {
          highlightDataSeries: false,
        },
        x: {
          show: true,
          formatter: undefined,
        },
        y: {
          show: true,
          formatter: undefined,
        },
        z: {
          show: true,
          formatter: undefined,
        },
        marker: {
          show: true,
        },
        fixed: {
          enabled: false,
          position: 'topRight',
          offsetX: 0,
          offsetY: 0,
        },
      },
    };

    return {
      markers: {
        size: 1,
      },
      series: [{
        name: "تعداد",
        data: [10, 41, 35, 51, 49, 62, 69, 91, 148]
      }],
      chartOptions: chartConfig,

    };
  },

};
</script>

<style scoped>
/* #chart1,
#chart2 {
  max-width: 650px;
  margin: 35px auto;
}

#chart2 {
  position: relative;
  margin-top: -70px;
  margin-bottom: 0px;
}

#app {
  padding-top: 20px;
  padding-left: 10px;
  background: #fff;
  border: 1px solid #ddd;
  box-shadow: 0 22px 35px -16px rgba(0, 0, 0, 0.1);
  max-width: 650px;
  margin: 35px auto;
} */
</style>
