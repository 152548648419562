<template>
  <v-col id="sidebar" @mouseover="$parent.isOpen = true || $parent.isHide == true" @mouseleave="$parent.isOpen = false || $parent.isHide == true" style="background-image: url(https://pazok.ir/satrex.png);background-blend-mode: color-burn;">
    <ul style="text-align: right;">
      <div>
        <router-link tag="li" to="/">
          <span v-if="$parent.isOpen" style="margin-left: 10px">داشبورد</span><i class="flaticon-dashboard"></i>
        </router-link>
        <hr>
      </div>
      <div>
        <li v-if="$parent.isOpen" @click="systemSettings = !systemSettings">
          <v-icon v-if="$parent.isOpen" :class="systemSettings ? 'iconRotate' : ''" style="top: 0">mdi-menu-right
          </v-icon>
          <span v-if="$parent.isOpen">تنظیمات سیستم</span><i class="flaticon-money-1"></i>
        </li>
        <hr>
      </div>
      <div :class="systemSettings ? 'open' : 'close'">
        <div>
          <router-link tag="li" to="cryptoSettings">
            <span v-if="$parent.isOpen">تنظیمات رمز ارز</span><i class="flaticon-money-1"></i>
          </router-link>
          <hr>
        </div>
        <div>
          <router-link tag="li" to="/marketSettings">
            <span v-if="$parent.isOpen">تنظیمات بازار</span><i class="flaticon-money-1"></i>
          </router-link>
          <hr>
        </div>
        <div>
          <router-link tag="li" to="/SiteSetting">
            <span v-if="$parent.isOpen">تنظیمات سامانه</span><i class="flaticon-bitcoin-symbol-inside-circulating-arrows"></i>
          </router-link>
          <hr>
        </div>
        <div>
          <router-link tag="li" to="/userSettings">
            <span v-if="$parent.isOpen">تنظیمات کاربری</span><i class="flaticon-bitcoin-symbol-inside-circulating-arrows"></i>
          </router-link>
          <hr>
        </div>
        <div>
          <router-link tag="li" to="/securityManage">
            <span v-if="$parent.isOpen">امنیت</span><i class="flaticon-bitcoin-symbol-inside-circulating-arrows"></i>
          </router-link>
          <hr>
        </div>
      </div>
      <div>
        <li v-if="$parent.isOpen" @click="referralShare = !referralShare">
          <v-icon v-if="$parent.isOpen" :class="referralShare ? 'iconRotate' : ''" style="top: 0">mdi-menu-right
          </v-icon>
          <span v-if="$parent.isOpen">کمپین ها</span><i class="flaticon-money-1"></i>
        </li>
        <hr>
      </div>
      <div :class="referralShare ? 'open' : 'close'" :style="referralShare ? 'height: 100px;' : 'height: 0px;'">
        <div>
          <router-link tag="li" :to="{ name: 'campaignsFinancialLevel' }">
            <span v-if="$parent.isOpen">کمپین </span><i class="flaticon-money-1"></i>
          </router-link>
          <hr>
        </div>
        <div>
          <router-link tag="li" :to="{ name: 'campaigns' }">
            <span v-if="$parent.isOpen">کمپین رفرال</span><i class="flaticon-money-1"></i>
          </router-link>
          <hr>
        </div>
        <!-- <div>
          <router-link tag="li" to="/campaigns/new">
            <span v-if="$parent.isOpen">اضافه کردن کمپین رفرال</span><i class="flaticon-money-1"></i>
          </router-link>
          <hr>
        </div> -->
      </div>
      <div>
        <router-link tag="li" :to="{ name: 'FinancialSetstings' }" v-if="$parent.isOpen" @click="financialSettings = !financialSettings">
          <span v-if="$parent.isOpen">تنظیمات مالی</span><i class="flaticon-money-1"></i>
        </router-link>
        <hr>
      </div>
      <div>
        <router-link tag="li" to="/userManage">
          <span v-if="$parent.isOpen">مدیریت کاربران</span><i class="flaticon-user"></i>
        </router-link>
        <hr>
      </div>
      <div>
        <router-link tag="li" to="/customerClub">
          <span v-if="$parent.isOpen">باشگاه مشتریان </span><i class="flaticon-user"></i>
        </router-link>
        <hr>
      </div>
      <div>
        <router-link tag="li" to="/adminMessages">
          <span v-if="$parent.isOpen">پیام های پشتیبانی</span><i class="flaticon-support"></i>
        </router-link>
        <hr>
      </div>
      <li @click="LoginOut()"><span v-if="$parent.isOpen">خروج</span><i class="flaticon-power-button"></i></li>
      <hr>
    </ul>
  </v-col>
</template>

<script>
import axiosApi from "@/axios";

export default {
  name: "Sidebar.vue",

  data() {
    return {
      systemSettings: false,
      financialSettings: false,
      referralShare: false,
    };
  },
  methods: {
    LoginOut() {
      sessionStorage.clear();
      localStorage.clear();
      axiosApi().post('api/Manage/v1/Token/Logout');
      location.replace('/');
    }
  }
};
</script>

<style scoped>
.close {
  height: 0 !important;
  overflow: hidden !important;
  margin: 0 !important;
  padding: 0 !important;
  transition: 0.3s;
}
.open {
  height: 270px;
  transition: 0.3s;
  background: #dbe0ea;
}
.openFinance {
  height: 160px;
  transition: 0.3s;
  background: #dbe0ea;
}
.iconRotate {
  transform: rotate(90deg);
}
</style>
